:root {
    --topnav-base-height: 56px;
    --topnav-base-height-fixed: 26px;
}
// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Spacing variables for navigation
$topnav-base-height: var(--topnav-base-height);
$topnav-base-height-fixed: var(--topnav-base-height-fixed);
$sidenav-base-width: 230px;
$sidenav-base: 225px;
$container-fluid-width: 1280px;
