// Base styling for the topnav

.sb-topnav {
    padding-left: 0;
    height: $topnav-base-height;
    z-index: $zindex-topnav;
    margin-left: 0;
    .navbar-brand {
        width: $sidenav-base-width;
        padding-left: $navbar-padding-x;
        padding-right: $navbar-padding-x;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 40px;
            height: 40px;
            object-fit: contain;
            margin-right: 10px;
        }
        .navbar-brand-name {
            font-weight: bold;
        }
    }
    &.navbar-dark {
        #sidebarToggle {
            color: $topnav-dark-toggler-color;
        }
    }
    &.navbar-light {
        &.bg-light {
            background-color: #f9fafc !important;
        }
        #sidebarToggle {
            color: $topnav-light-toggler-color;
        }
    }
}
