/* You can add global styles to this file, and also import other style files */

.cdk-overlay-container {
    z-index: 2500;
}

label[required]::after {
    content: '*';
    color: red;
}

.col-left {
    @media only screen and (min-width: 768px) {
        padding-right: 5px !important;
    }
}
.col-center {
    @media only screen and (min-width: 768px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
.col-right {
    @media only screen and (min-width: 768px) {
        padding-left: 5px !important;
    }
}

.col-space-between {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
}

.pac-container {
    z-index: 100000;
}

.disabled-input-form {
    color: #aaa;
    background: #f5f5f5;
    cursor: not-allowed; /* Cursor change to disabled state*/
}

.disabled-status {
    cursor: no-drop !important;
}
