// Global styling for this template

html,
body {
    height: 100%;
}

// Set spacing on containers for page symmetry

.container,
.container-fluid {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
}

.btn:focus {
    box-shadow: none !important;
}

.label-input-filter {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--guilliman-blue) !important;

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--guilliman-blue) !important;
    }
}

.label-input-filter-height {
    // the line-height of .label-input-filter
    height: 20px;
}

.text-color-primary {
    color: var(--color-garnish) !important;
}

.bg-color-primary {
    background-color: var(--color-garnish) !important;
}

.master-data {
    .tb-action {
        text-align: end !important;
    }

    .ant-table-thead > tr > th {
        background: none !important;
        border-bottom: none !important;
    }

    .ant-table-tbody > tr > td {
        border-bottom: none !important;
    }

    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        background-color: unset !important;
    }
}

.h-0 {
    height: 0 !important;
}

@for $i from 10 through 1000 {
    .w-#{$i}-px {
        width: #{($i) + 'px'} !important;
    }

    .min-w-#{$i}-px {
        min-width: #{($i) + 'px'} !important;
    }

    .max-w-#{$i}-px {
        max-width: #{($i) + 'px'} !important;
    }

    .h-#{$i}-px {
        height: #{($i) + 'px'} !important;
    }

    .min-h-#{$i}-px {
        min-height: #{($i) + 'px'} !important;
    }

    .max-h-#{$i}-px {
        max-height: #{($i) + 'px'} !important;
    }

    .m-#{$i}-px {
        margin: #{($i) + 'px'} !important;
    }

    .p-#{$i}-px {
        padding: #{($i) + 'px'} !important;
    }
}

.cht-form-error {
    $color: var(--color-red);

    .hint {
        color: $color;
    }

    input,
    nz-select-top-control {
        border-color: $color !important;
    }
}

.object-fit-cover {
    object-fit: cover !important;
}

.cht-btn-add {
    margin-left: auto;

    i {
        color: var(--blue-sparkle);
        border: 0.5px solid var(--blue-sparkle);
        border-radius: 10px;
        cursor: pointer;
        padding: 0.3rem;
    }
}

.btn-cursor {
    cursor: pointer;
}

.btn-radius {
    border-radius: 6px !important;
}

.cursor-default {
    cursor: default !important;
}

.z-index-1 {
    z-index: 1;
}

.text-red {
    color: red !important;
}

.text-blue {
    color: #007aff;
}

.text-violet {
    color: #7b61ff;
}

.text-orange {
    color: #ffa451;
}

.text-gray {
    color: gray !important;
}

.text-green {
    color: var(--color-garnish) !important;
}

.text-black {
    color: black;
}

.text-sky-blue {
    color: var(--arty-lick-deep-sky-blue);
}

.ng-select {
    .ng-value-label {
        font-size: 14px;
        font-weight: normal;
    }

    .ng-clear {
        display: none !important;
    }
}

.anticon-plus-circle {
    font-size: 18px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.cursor-pointer {
    cursor: pointer;
}

.ellipsis-text {
    overflow: hidden; /* make sure it hides the content that overflows */
    white-space: nowrap; /* don't break the line */
    text-overflow: ellipsis; /* give the beautiful '...' effect */
}

.ant-input-number-handler-wrap {
    display: none;
}

.ant-modal-wrap {
    overflow: hidden !important;
}

.ng-select {
    .ng-dropdown-panel {
        top: calc(100% + 10px) !important;
    }

    .ng-value-container .ng-input {
        top: 10px;
    }
}

.border-1-garnish {
    border: 1px solid var(--color-garnish);
}

//customer master-data
.master-data {
    .tb-action {
        text-align: end !important;
    }

    .ant-table-thead > tr > th {
        background: none !important;
        border-bottom: none !important;
    }

    .ant-table-tbody > tr > td {
        border-bottom: none !important;
    }

    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        background-color: unset !important;
    }
}

.ant-table-wrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 1rem;
    margin: 1rem 0;

    .ant-table-container {
        overflow-y: hidden;
        overflow-x: auto;
    }

    tbody {
        tr:last-child {
            td:first-child {
                border-bottom-left-radius: 1rem;
            }

            td:last-child {
                border-bottom-right-radius: 1rem;
            }
        }
    }
}
