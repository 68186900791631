// Base styling for the sidenav

.sb-sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    position: relative;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 9%);
    .sb-sidenav-user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .sb-sidenav-user-avatar {
            width: 90px;
            height: 90px;
            object-fit: cover;
            border-radius: 50%;
            margin-bottom: 12px;
        }
        .sb-sidenav-user-name {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 1px;
        }
        .sb-sidenav-user-role {
            font-size: 13px;
            margin-bottom: 5px;
        }
    }
    .sb-sidenav-menu {
        flex-grow: 1;
        .nav {
            flex-direction: column;
            flex-wrap: nowrap;
            .sb-sidenav-menu-heading {
                padding: 1.75rem 1rem 0.75rem;
                font-size: 0.75rem;
                font-weight: bold;
                text-transform: uppercase;
            }
            .nav-link {
                display: flex;
                align-items: center;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                position: relative;
                font-size: 0.8rem;

                .prefix-active {
                    display: none;
                }
                &.active .prefix-active {
                    display: block;
                    position: absolute;
                    width: 4px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background-color: $sidenav-light-link-active-color;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
                .sb-nav-link-icon {
                    font-size: 0.9rem;
                }
                .sb-sidenav-collapse-arrow {
                    display: inline-block;
                    margin-left: auto;
                    transition: transform 0.15s ease;
                }
                &.collapsed {
                    .sb-sidenav-collapse-arrow {
                        transform: rotate(-90deg);
                    }
                }
            }
            .sb-sidenav-menu-nested {
                margin-left: 1.5rem;
                flex-direction: column;
            }
        }
    }
    .sb-sidenav-footer {
        padding: 0.75rem;
    }
}
