.sb-nav-fixed {
    .sb-topnav {
        @extend .fixed-top;
        z-index: $zindex-topnav;
    }
    #layoutSidenav {
        #layoutSidenav_nav {
            @extend .fixed-top;
            width: $sidenav-base;
            height: 100vh;
            z-index: $zindex-sidenav;
            .sb-sidenav {
                padding-top: $topnav-base-height-fixed;
                .sb-sidenav-menu {
                    overflow-y: auto;
                }
            }
        }
        #layoutSidenav_content {
            padding-left: $sidenav-base-width;
            margin-top: $topnav-base-height;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%),
                0 1px 2px 0 rgb(0 0 0 / 9%);
        }
    }
}
