:root {
    --color-light: #454758;
    --color-white: #ffffff;
    --color-garnish: #219653;
    --color-sea-green: rgba(33, 150, 83, 0.2);
    --guilliman-blue: #5f6377;
    --cerebral_grey: #cccccc;
    --arty-lick-deep-sky-blue: #007fff;
    --cultured: #f5f5f5;
    --color-red: #ff0000;
    --lotion: #fafafa;
    --blue-sparkle: #007aff;
    --venetian-nights: #7b61ff;
    --angry-flamingo: #f15642;
    --sunflower-yellow: #ffc857;
    --electric-purple: #bf00ff;
    --coral: #ff7f50;
    --emerald-green: #50c878;
    --lavender: #e6e6fa;
    --chocolate: #d2691e;
    --indian-red: #cd5c5c;
    --lime-green: #32cd32;
    --misty-rose: #ffe4e1;
    --deep-sky-blue: #00bfff;
    --cadet-blue: #5f9ea0;
    --goldenrod: #daa520;
    --navy: #000080;
    --powder-blue: #b0e0e6;
    --rosy-brown: #bc8f8f;
    --steel-blue: #4682b4;
    --dark-khaki: #bdb76b;
    --orange-red: #ff4500;
    --saddle-brown: #8b4513;
    --medium-violet-red: #c71585;
    --medium-blue: #0000cd;
    --dark-olive-green: #556b2f;
    --indigo: #4b0082;
    --sienna: #a0522d;
    --firebrick: #b22222;
    --dark-magenta: #8b008b;
    --medium-sea-green: #3cb371;
    --slate-gray: #708090;
    --dark-turquoise: #00ced1;
    --teal: #008080;
    --medium-spring-green: #00fa9a;
    --golden-yellow: #ffd700;
    --maroon: #800000;
    --cornflower-blue: #6495ed;
    --salmon: #fa8072;
    --tomato: #ff6347;
    --medium-aquamarine: #66cdaa;
    --purple: #800080;
    --dark-slate-gray: #2f4f4f;
    --dark-orange: #ff8c00;
}

@each $color,
    $value
        in (
            'light': var(--color-light),
            'white': var(--color-white),
            'garnish': var(--color-garnish),
            'sea-green': var(--color-sea-green),
            'guilliman-blue': var(--guilliman-blue),
            'cerebral_grey': var(--cerebral_grey),
            'arty-lick-deep-sky-blue': var(--arty-lick-deep-sky-blue),
            'cultured': var(--cultured),
            'color-red': var(--color-red),
            'lotion': var(--lotion),
            'blue-sparkle': var(--blue-sparkle),
            'venetian-nights': var(--venetian-nights),
            'angry-flamingo': var(--angry-flamingo),
            'sunflower-yellow': var(--sunflower-yellow),
            'electric-purple': var(--electric-purple),
            'coral': var(--coral),
            'emerald-green': var(--emerald-green),
            'lavender': var(--lavender),
            'chocolate': var(--chocolate),
            'indian-red': var(--indian-red),
            'lime-green': var(--lime-green),
            'misty-rose': var(--misty-rose),
            'deep-sky-blue': var(--deep-sky-blue),
            'cadet-blue': var(--cadet-blue),
            'goldenrod': var(--goldenrod),
            'navy': var(--navy),
            'powder-blue': var(--powder-blue),
            'rosy-brown': var(--rosy-brown),
            'steel-blue': var(--steel-blue),
            'dark-khaki': var(--dark-khaki),
            'orange-red': var(--orange-red),
            'saddle-brown': var(--saddle-brown),
            'medium-violet-red': var(--medium-violet-red),
            'medium-blue': var(--medium-blue),
            'dark-olive-green': var(--dark-olive-green),
            'indigo': var(--indigo),
            'sienna': var(--sienna),
            'firebrick': var(--firebrick),
            'dark-magenta': var(--dark-magenta),
            'medium-sea-green': var(--medium-sea-green),
            'slate-gray': var(--slate-gray),
            'dark-turquoise': var(--dark-turquoise),
            'teal': var(--teal),
            'medium-spring-green': var(--medium-spring-green),
            'golden-yellow': var(--golden-yellow),
            'maroon': var(--maroon),
            'cornflower-blue': var(--cornflower-blue),
            'salmon': var(--salmon),
            'tomato': var(--tomato),
            'medium-aquamarine': var(--medium-aquamarine),
            'purple': var(--purple),
            'dark-slate-gray': var(--dark-slate-gray),
            'dark-orange': var(--dark-orange)
        )
{
    .bg-#{$color} {
        background-color: $value;
    }

    .text-#{$color} {
        color: $value;
    }
}
